import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Home from './Containers/Home'
import SchoolList from './Containers/SchoolList'
import UniversityLogin from './Containers/university/UniversityLogin'
import ApplicationForm from './Containers/ApplicationForm'
import UniversityApplicationForm from './Containers/university/UniversityApplicationForm'
import PaymentForm from './Containers/PaymentForm'
import DownloadForm from './Containers/DownloadForm'



const App = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={SchoolList} />
        <Route exact path="/university/login" component={UniversityLogin} />
        <Route
          exact
          path="/online-applicant-info/:id"
          component={ApplicationForm}
        />          
        <Route
          exact
          path="/university/online-applicant-info/:id"
          component={UniversityApplicationForm}
        />          
        <Route
          exact
          path="/online-applicant-fee-payment/:id"
          component={PaymentForm}
        />
        <Route
          exact
          path="/online-applicant-confirmation-paper/"
          component={DownloadForm}
        />
        {/* <Route component={PageNotFound} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
