import React, { useState, useEffect } from "react";
import Footer from '../Components/Footer';
import {
  Box,
  Button,
  TextField as TextF,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useHistory } from "react-router-dom";
import logo from './logo.svg'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight:80,
    alignItems:'center',
    content:'center',
    display:"flex",
    alignContent:"center",
    justifyContent:'center',
    fontWeight:"bold",
    cursor:"pointer",
    marginTop:20,
    marginBottom:10
  },
}));



const Home = (props) => {

  const history = useHistory();
  const onSubmitCollecge = (e) => {
    e.preventDefault();
    history.push("/login");
  };
  const onSubmitUniversity = (e) => {
    e.preventDefault();
    history.push("/university/login");
  };
  const classes = useStyles();

  return (

    <>
    <div style={{display:"flex", alignContent:"center", justifyContent:"center", marginTop:20}}>
    <img src={logo} style={{ height: 100 }} />
    </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>School Admission</Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper onClick={(e)=>onSubmitCollecge(e)} className={classes.paper}>College Admission</Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper} onClick={(e)=>onSubmitUniversity(e)} >University Admission</Paper>
        </Grid>
        </Grid>
    </>
  );
};

export default Home;